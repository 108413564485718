import { financeService, setTotalToLocation } from "../../service"
import { set_list_by_cash, set_list_by_credit, set_transaction_records } from "../reducer.finance";
import { checkStatus } from "../util";
import { saveAs } from 'file-saver';
import { start_loading, stop_loading } from "../reducer.document"
import { component_reducer } from "..";
/**
 * credit list
 */
export const action_fetch_credit_waybill_list = (data) => {
    return async (dispatch, getState) => {
        try {
            const response = await financeService.get_waybill_to_make_purchase({
                payment_group: 'credit',
                ...data
            });
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_list_by_credit(body?.docs || []));
                //setTotalToLocation(body.totalDocs || 0)
                dispatch(component_reducer.set_total_count(body['totalDocs'] || 0))
            }
        } catch (error) {
            console.log(error)
        }
    }
}

/**
 * cash list
 */
export const action_fetch_cash_waybill_list = (data) => {
    return async (dispatch, getState) => {
        try {
            const response = await financeService.get_waybill_to_make_purchase({
                payment_group: 'cash',
                ...data
            });
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_list_by_cash(body?.docs || []));
                //setTotalToLocation(body.totalDocs || 0)
                dispatch(component_reducer.set_total_count(body['totalDocs'] || 0))
            }
        } catch (error) {
            console.log(error)
        }
    }
}

/**
 * search cash list
 */
export const action_search_cash_waybill_list = (bodyData, payment_group) => {
    return async (dispatch, getState) => {
        try {
            const response = await financeService.search_waybill_to_make_purchase(bodyData);
            if (checkStatus(response)) {
                const body = await response.data;
                if (payment_group === 'cash') {
                    dispatch(set_list_by_cash(body?.docs || []));
                } else {
                    dispatch(set_list_by_credit(body?.docs || []));
                }

                //setTotalToLocation(body.totalDocs || 0)
                dispatch(component_reducer.set_total_count(body['totalDocs'] || 0))
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export const export_finance = (force = false, data) => {
    return async (dispatch, getState) => {

        dispatch(start_loading())
        try {
            const response = await financeService.export_finance(data)
            if (checkStatus(response)) {
                const blob = await response.data;
                saveAs(blob, 'finance.csv')
            }
            else dispatch(stop_loading())

        } catch (error) {
            console.log(error)
            dispatch(stop_loading())
        }
    }
}
export const export_finance_records = (force = false) => {
    return async (dispatch, getState) => {

        dispatch(start_loading())
        try {
            const response = await financeService.export_finance_record()
            if (checkStatus(response)) {
                const blob = await response.data;
                saveAs(blob, 'transactionrecords.csv')
            }
            else dispatch(stop_loading())

        } catch (error) {
            console.log(error)
            dispatch(stop_loading())
        }
    }
}


export const action_fetch_transaction_records = (force = false, bodyData = {}) => {
    return async (dispatch, getState) => {
        try {
            console.log('Action fetch transaction records');
            const response = await financeService.get_transaction(force, bodyData);
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_transaction_records(body['docs'] || []));
                //setTotalToLocation(body['totalDocs'] || 0)
            }
        } catch (error) {
            console.log(error)
        }
    }
}

// export const action_search_transaction_records = (force = false, bodyData) => {
//     return async (dispatch, getState) => {
//         try {
//             const response = await financeService.search_transaction(bodyData);
//             if (checkStatus(response)) {
//                 const body = await response.data;
//                 console.log(body);
//                 dispatch(set_transaction_records(body['docs'] || []));
//                 //setTotalToLocation(body['totalDocs'] || 0)
//             }
//         } catch (error) {
//             console.log(error)
//         }
//     }
// }