import {
    set_error_message,
    set_user_list,
    start_loading
} from "../reducer.system";
import { informationservice, userService } from '../../service';
import { checkStatus } from "../util";
import { set_delivery_list } from "../reducer.document";
import { set_employee_option } from "../reducer.system";
import { component_reducer } from "..";

export const action_fetch_user_list = (force = false) => {
    return async (dispatch, getState) => {
        dispatch(start_loading());

        const user_list = getState()?.system?.user_list || []

        if (!force && Array.isArray(user_list) && user_list.length > 0) return;

        try {
            const response = await userService.getUserList({
                refresh: force
            });

            if (checkStatus(response)) {
                const body = await response.data
                dispatch(set_user_list(body.docs));
            }
            else dispatch(set_error_message(response.statusText))

        } catch (error) {
            dispatch(set_error_message(error.message));
        }
    }
}

/**
 * fetch only delivery list for waybill query and assign 
 */
export const action_fetch_delivery_list = (data, force = false) => {
    return async (dispatch, getState) => {
        try {
            const delivery_list = getState()?.document?.delivery_list || []
            if (!force && Array.isArray(delivery_list) && delivery_list.length > 0)
                return;

            dispatch(start_loading());

            const response = await userService.getDeliveryList(data)

            if (checkStatus(response)) {
                const body = await response.data
                dispatch(set_delivery_list(body.docs || []))
            }
            else dispatch(set_error_message(response.statusText || 'error'))
        } catch (error) {
            dispatch(set_error_message(error.message));
        }
    }
}

export const action_fetch_employee_option = (force = false) => {
    return async (dispatch, getState) => {
        try {
            const employeeOption = getState()?.user?.employee_option || []

            if (!force && Array.isArray(employeeOption) && employeeOption.length > 0) {
                return;
            }
            const response = await informationservice.get_employee_option();

            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_employee_option(body));
            }
            else dispatch(set_error_message('No data'));
        } catch (error) {
            dispatch(set_error_message(error.message));
        }
    }
}

export const action_delete_user = (id) => {
    return async (dispatch, getState) => {
        try {
            const response = await userService.deleteUser(id);
            if (checkStatus(response)) {
                const data = response.data
                dispatch(component_reducer.set_snack_bar_content({
                    message: data?.message || 'Successfully deleted record',
                    type: 'success'
                }))
                dispatch(action_fetch_user_list(true));
            }
            else {
                dispatch(component_reducer.set_snack_bar_content({
                    message: 'Something went wrong',
                    type: 'error'
                }))
            }
        } catch (error) {
            dispatch(component_reducer.set_snack_bar_content({
                message: error?.message || error?.error?.message || error?.errors[0]?.msg,
                type: 'error'
            }))
        }
    }
}