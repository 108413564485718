import * as common from './type';
import _ from 'lodash';

const types = {
    SET_SCAN_LIST: 'SET_SCAN_LIST',
    SCAN_TOGGLE_TO_INDEX: "SCAN_TOGGLE_TO_INDEX",
    SCAN_TOGGLE_ALL_LIST: "SCAN_TOGGLE_ALL_LIST",
}

export const initialState = {
    isLoading: false,
    errorMessage: '',

    receiving_list: [],
    delivery_list: [],
    sign_list: [],
    problem_list: [],
}

const Index = (state = initialState, action) => {
    switch (action.type) {
        case common.START_LOADING:
            return {
                ..._getCommonState(state),
                isLoading: true
            }
        case common.STOP_LOADING:
            return {
                ..._getCommonState(state),
                isLoading: false
            }
        case common.SET_ERROR_MESSAGE:
            return {
                ..._getCommonState(state),
                errorMessage: action.payload
            }

        case types.SET_SCAN_LIST:
            try {

                const { field, payload } = action

                const temp = _.cloneDeep(state);

                temp[field] = payload

                return temp;
            } catch (error) {
                return {
                    ..._getCommonState(state)
                }
            }

        /**
        * TOGGLE TO INDEX
        */
        case types.SCAN_TOGGLE_TO_INDEX:
            try {
                const { index, status, field } = action

                if (!field)
                    throw new Error('Invalid field!');

                const dump = _.cloneDeep(state[field]) || [];
                let dump_item = _.cloneDeep(dump[index]);

                dump_item = {
                    ...dump_item,
                    checked: status
                }

                dump[index] = dump_item

                const temp = {
                    ..._getCommonState(state)
                }

                temp[field] = dump;

                return temp

            } catch (error) {
                return {
                    ..._getCommonState(state),
                }
            }


        /**
         * TOGGLE ALL LIST
         */
        case types.SCAN_TOGGLE_ALL_LIST:
            try {
                const { status, field } = action

                if (!field)
                    throw new Error('Invalid field !')

                const dump = _.cloneDeep(state[field]) || [];

                if (!Array.isArray(dump))
                    return { ..._getCommonState(state) }

                if (dump.length === 0)
                    return { ..._getCommonState(state) }

                let updated_list = dump.map(d => ({
                    ...d,
                    checked: status
                }))

                const temp = {
                    ..._getCommonState(state)
                }

                temp[field] = updated_list;

                return temp

            } catch (error) {
                return {
                    ..._getCommonState(state),
                }
            }
        default:
            return {
                ..._getCommonState(state)
            }
    }
}

export default Index;

const _getCommonState = (state) => ({
    ...state,
    isLoading: false,
    errorMessage: ''
})

/**
 * set data or payload to scan field
 * @param {*} data 
 * @param {*} field 
 */
export const set_scan_field_data = (data = [], field) => ({
    type: types.SET_SCAN_LIST,
    payload: data,
    field
})

/**
 * update progress status to item 
 */
export const toggle_to_index = ({ index, status, field }) => ({
    type: types.SCAN_TOGGLE_TO_INDEX,
    index,
    status,
    field
})

export const toggle_to_list = (status, field) => ({
    type: types.SCAN_TOGGLE_ALL_LIST,
    status,
    field
})