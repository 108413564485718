import {
  axiosInstance as httpService,
  authInstance as httpAuth,
} from "./httpService";

import * as userService from "./service.user";
import * as roleService from "./service.userrole";
import * as informationservice from "./service.information";
import * as optionService from "./service.option";
import * as documentService from "./service.document";
import * as authService from "./service.auth";
import * as shippingInventoryService from "./service.shipping_inventory";
import * as financeService from "./service.finance";
import * as locationService from "./service.location";
import * as scanningService from "./service.scan";
import * as dashboardService from "./service.dashboard";

const getPaginationQuery = () => {
  const query = new URLSearchParams(window.location.search);

  return query.toString();

  // const { page, limit } = GET_STORED_PAGINATION_INFO
  // console.log(page,limit)
  // return `page=${page}&limit=${limit}`
};

const setTotalToLocation = (total = 0) => {
  const query = new URLSearchParams(window.location.search);

  query.set("total", total);

  window.history.replaceState(null, null, `?${query.toString()}`);
};

const getDataQuery = (data) => {
  const query = Object.keys(data)
    .map((key) => {
      return key + "=" + data[key];
    })
    .join("&");
  return query.toString();
};

export {
  httpService,
  httpAuth,
  userService,
  roleService,
  informationservice,
  optionService,
  documentService,
  authService,
  shippingInventoryService,
  financeService,
  locationService,
  scanningService,
  dashboardService,
  /**
   * util for table pagination
   */
  getPaginationQuery,
  setTotalToLocation,
  getDataQuery,
};
