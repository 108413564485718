import * as common from '../type'

export const initialState = {
    isLoading: false,
    errorMessage: '',
    region_list: [],
    district_list: [],
    township_list: [],

    itemtype_list: [],
    payment_type_list: [],
    shipping_type_list: [], // express type
    shipping_mode_list: [],
    problem_type_list: [],

    /**
     * scan type option list for waybill query filter
     */
    scantype_option: [],

    /**
     * finance
     */
    collectiontype_list: [],

    payment_group: [],
}

/**
 * Reducer
 */
const Index = (state = initialState, action) => {
    switch (action.type) {
        case common.START_LOADING:
            return {
                ..._getCommonState(state),
                isLoading: true
            }
        case common.STOP_LOADING:
            return {
                ..._getCommonState(state),
            }
        case common.SET_REGION_LIST: {
            return {
                ..._getCommonState(state),
                region_list: action.payload
            }
        }
        case common.SET_DISTRICT_LIST: {
            return {
                ..._getCommonState(state),
                district_list: action.payload
            }
        }
        case common.SET_TOWNSHIP_LIST: {
            return {
                ..._getCommonState(state),
                township_list: action.payload
            }
        }
        case common.SET_ITEMTYPE_LIST: {
            return {
                ..._getCommonState(state),
                itemtype_list: action.payload
            }
        }
        case common.SET_PAYMENT_TYPE_LIST: {
            return {
                ..._getCommonState(state),
                payment_type_list: action.payload
            }
        }
        case common.SET_SHIPPING_TYPE_LIST: {
            return {
                ..._getCommonState(state),
                shipping_type_list: action.payload
            }
        }
        case common.SET_SHIPPING_MODE_LIST: {
            return {
                ..._getCommonState(state),
                shipping_mode_list: action.payload
            }
        }
        case common.SET_PROBLEM_TYPE_LIST:
            return {
                ..._getCommonState(state),
                problem_type_list: action.payload
            }
        case common.SET_SCAN_TYPE_OPTION:
            return {
                ..._getCommonState(state),
                scantype_option: action.payload
            }
        case common.SET_PAYMENT_GROUP:
            return {
                ..._getCommonState(state),
                payment_group: action.payload
            }
        case common.SET_COLLECTION_TYPE:
            return {
                ..._getCommonState(state),
                collectiontype_list: action.payload
            }

        case common.SET_SIGN_WAYBILL:
            return {
                ..._getCommonState(state),
                sign_waybill_option: action.payload
            }
        default:
            return state
    }
}

const _getCommonState = (state) => ({
    ...state,
    isLoading: false,
    errorMessage: ''
})


/**
 * action
 */

export const start_loading = () => ({
    type: common.START_LOADING
})

export const stop_loading = () => ({
    type: common.STOP_LOADING
})

export const set_region_list = (data = []) => ({
    type: common.SET_REGION_LIST,
    payload: data
})

export const set_district_list = (data = []) => ({
    type: common.SET_DISTRICT_LIST,
    payload: data
})

export const set_township_list = (data = []) => ({
    type: common.SET_TOWNSHIP_LIST,
    payload: data
})

export const set_itemtype_list = (data = []) => ({
    type: common.SET_ITEMTYPE_LIST,
    payload: data
})

export const set_payment_type_list = (data = []) => ({
    type: common.SET_PAYMENT_TYPE_LIST,
    payload: data
})

export const set_shipping_type_list = (data = []) => ({
    type: common.SET_SHIPPING_TYPE_LIST,
    payload: data
})

export const set_shipping_mode_list = (data = []) => ({
    type: common.SET_SHIPPING_MODE_LIST,
    payload: data
})

export const set_problem_option = (list = []) => ({
    type: common.SET_PROBLEM_TYPE_LIST,
    payload: list
})

export const set_scan_type_option = (list = []) => ({
    type: common.SET_SCAN_TYPE_OPTION,
    payload: list
})

export const set_truck_option = (list = []) => ({
    type: common.SET_TRUCK_OPTION,
    payload: list
})

export const set_payment_group = (list = []) => ({
    type: common.SET_PAYMENT_GROUP,
    payload: list
})

export const set_collection_type = (list = []) => ({
    type: common.SET_COLLECTION_TYPE,
    payload: list
})

export const set_sign_waybill_option = (list = []) => ({
    type: common.SET_SIGN_WAYBILL,
    payload: list
})

export default Index;