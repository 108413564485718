const types = {

    SET_REGION_LIST: "SET_REGION_LIST",
    SET_DISTRICT_LIST: "SET_DISTRICT_LIST",
    SET_TOWNSHIP_LIST: "SET_TOWNSHIP_LIST",

    SET_DISTRICT_OPTION_FOR_ADDRESS: "SET_DISTRICT_OPTION_FOR_ADDRESS",
    SET_TOWNSHIP_OPTION_FOR_ADDRESS: "SET_TOWNSHIP_OPTION_FOR_ADDRESS",
    RESET_OPTION: "RESET_OPTION"
}

export const initialState = {
    isLoading: false,
    errorMessage: '',
    region_list: [],
    district_list: [],
    township_list: [],

    district_option: [],
    township_option: []
}

/**
 * reducer
 */
const Index = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_COUNTRY_LIST:
            return {
                ..._getCommonState(state),
                country_list: action.payload
            }

        case types.SET_REGION_LIST:
            return {
                ..._getCommonState(state),
                region_list: action.payload
            }

        case types.SET_DISTRICT_LIST:
            return {
                ..._getCommonState(state),
                district_list: action.payload
            }

        case types.SET_TOWNSHIP_LIST:
            return {
                ..._getCommonState(state),
                township_list: action.payload
            }
        case types.SET_DISTRICT_OPTION_FOR_ADDRESS:
            return {
                ..._getCommonState(state),
                district_option: action.payload
            }
        case types.SET_TOWNSHIP_OPTION_FOR_ADDRESS:
            return {
                ..._getCommonState(state),
                township_option: action.payload
            }
        case types.RESET_OPTION:
            return {
                ..._getCommonState(state),
                district_option: [],
                township_option: []
            }
        default:
            return {
                ..._getCommonState(state)
            }
    }
}

export default Index;


const _getCommonState = (state) => ({
    ...state,
    isLoading: false,
    errorMessage: ''
})

/**
 * action
 */
export const set_country_list = (data = []) => ({
    type: types.SET_COUNTRY_LIST,
    payload: data
})


export const set_region_list = (data = []) => ({
    type: types.SET_REGION_LIST,
    payload: data
})

export const set_district_list = (data = []) => ({
    type: types.SET_DISTRICT_LIST,
    payload: data
})

export const set_township_list = (data = []) => ({
    type: types.SET_TOWNSHIP_LIST,
    payload: data
})

export const set_district_option = (data = []) => ({
    type: types.SET_DISTRICT_OPTION_FOR_ADDRESS,
    payload: data
})

export const set_township_option = (data = []) => ({
    type: types.SET_TOWNSHIP_OPTION_FOR_ADDRESS,
    payload: data
})

export const reset_option = () => ({
    type: types.RESET_OPTION,
})