import _ from 'lodash';
import { saveAs } from 'file-saver';
import { component_reducer, WAYBILL_STATUS } from "..";
import { documentService, shippingInventoryService } from "../../service"
import {
    set_list_view_item,
    set_way_list
} from "../reducer.cargo_diversion_center/reducer.cargo_loading";
import {
    set_list_view_item as set_unloading_list_view,
    set_confirmed_way_list
} from "../reducer.cargo_diversion_center/reducer.cargo_unloading";
import { set_destination_inventory } from "../reducer.cargo_diversion_center/reducer.distinction_inventory";
import { set_shipping_inventory } from "../reducer.cargo_diversion_center/reducer.shipping_inventory";
import { set_able_to_confirm_status, set_first_table_row, set_second_table_row } from "../reducer.cargo_diversion_center/reducer.truck_loading";
import { checkStatus } from "../util";

import { set_search_option, start_loading, stop_loading } from "../reducer.document"
import { set_sign_query_list } from "../reducer.sign";

/**
 * 
 * @param {*} array 
 * @param {*} status 
 * @returns array where same status of item
 */
function getMatchedItem(array = [], status, optional_status) {
    const size = array.length
    let i = 0, temp = []
    let status_list = [status]

    if (optional_status)
        status_list = [...status_list, ...optional_status]

    for (; i < size; i++) {
        if (status_list.includes(array[i].currentStatus))
            temp.push(array[i]._id);
    }
    return temp
}

/**
 * 
 * @param {*} force  for state data check
 * @param {*} for_unloading for way list 
 * if false , list will get for cargo-loading as default
 * if true  , list will get for cargo-unloading 
 * @returns 
 */
export const action_fetch_way_list = (force = false, for_unloading = false, data = {}) => {
    return async (dispatch, getState) => {
        try {

            const respones = await shippingInventoryService.get_all_way(force, for_unloading, data);

            if (checkStatus(respones)) {
                const body = await respones.data;

                if (for_unloading)
                    dispatch(set_confirmed_way_list(body));

                else
                    dispatch(set_way_list(body));
            }

        } catch (error) {
            console.log(error)
        }
    }
}

/**
 * fetch cargo waybill list by status for cargo load
 */
export const action_fetch_cargo_loading_data = (current_way, destination) => {
    return async (dispatch, getState) => {
        /**
         * fetch loaded waybill
         */
        await CommonAction(dispatch, {
            status: WAYBILL_STATUS.LOADED,
            current_way: current_way,
            destination
        })
    }
}



/**
 * fetch cargo waybill list by status for cargo unload
 */
export const action_fetch_cargo_unloading_data = (current_way, destination) => {
    return async (dispatch, getState) => {
        /**
         * fetch loaded waybill
         */
        await CommonAction(dispatch, {
            status: WAYBILL_STATUS.UNLOADED,
            current_way: current_way,
            destination
        })
    }
}

/**
 * 
 * @param {*} dispatch 
 * @param {*} payload {status,current_way_id}
 */
const CommonAction = async (dispatch, payload) => {
    try {
        const response = await documentService.get_cargo_way_bill_list(payload);

        if (checkStatus(response)) {

            const body = await response.data;
            const data = body['docs'];

            switch (payload.status) {
                case WAYBILL_STATUS.CREATED:
                case WAYBILL_STATUS.CONFIRMED:
                    dispatch(set_first_table_row(data));
                    break;

                case WAYBILL_STATUS.LOADED:
                case WAYBILL_STATUS.UNLOADED:
                    dispatch(set_second_table_row(data));
                    break;

                default:
                    break;
            }
        }
    } catch (error) {
        const size = (Array.isArray(error.errors) && error.errors.length > 0) ? error.errors.length - 1 : null

        dispatch(component_reducer.set_snack_bar_content({
            message: error['message'] || (
                size > -1 ? error?.errors[size].msg
                    :
                    'Oop something went wrong in getting data!'
            ),
            type: 'error'
        }))
    }
}

/**
 * fetch loaded item
 */
export const action_fetch_loaded_item = (current_way, status = WAYBILL_STATUS.LOADED) => {
    return async (dispatch, getState) => {
        try {

            const loaded_response = await documentService.get_cargo_way_bill_list({
                status: status,
                current_way: current_way._id,
                list_for: true
            });

            if (checkStatus(loaded_response)) {
                const body = await loaded_response.data;
                const docs = body['docs']
                if (Array.isArray(docs) && docs.length > 0)
                    dispatch(set_list_view_item(body['docs'], current_way));

                else {

                    dispatch(set_list_view_item([]));
                    dispatch(component_reducer.set_snack_bar_content({
                        message: `No data is available for the way : ${current_way.way_number}`,
                        type: 'warning'
                    }))

                }
            }

            else
                dispatch(component_reducer.set_snack_bar_content({
                    message: 'Error occur while fetchin Loaded waybill list',
                    type: 'error'
                }))


        } catch (error) {
            const size = (Array.isArray(error.errors) && error.errors.length > 0) ? error.errors.length - 1 : null
            dispatch(component_reducer.set_snack_bar_content({
                message: error?.message || (
                    size ? error?.errors[size].msg
                        :
                        'Oop something went wrong in getting data!'
                ),
                type: 'error'
            }))
        }
    }
}

/**
 * fetch unloaded item
 */
export const action_fetch_unloaded_item = (current_way, status = WAYBILL_STATUS.UNLOADED) => {
    return async (dispatch, getState) => {
        try {

            const loaded_response = await documentService.get_cargo_way_bill_list({
                status: status,
                current_way: current_way._id,
                list_for: true
            });

            if (checkStatus(loaded_response)) {
                const body = await loaded_response.data;
                const docs = body['docs']
                if (Array.isArray(docs) && docs.length > 0)
                    dispatch(set_unloading_list_view(body['docs'], current_way));

                else {

                    dispatch(set_list_view_item([]));
                    dispatch(component_reducer.set_snack_bar_content({
                        message: `No data is available for the way : ${current_way.way_number}`,
                        type: 'warning'
                    }))

                }
            }

            else
                dispatch(component_reducer.set_snack_bar_content({
                    message: 'Error occur while fetchin Loaded waybill list',
                    type: 'error'
                }))


        } catch (error) {
            const size = (Array.isArray(error.errors) && error.errors.length > 0) ? error.errors.length - 1 : null
            dispatch(component_reducer.set_snack_bar_content({
                message: error?.message || (
                    size ? error?.errors[size].msg
                        :
                        'Oop something went wrong in getting data!'
                ),
                type: 'error'
            }))
        }
    }
}

/**
 * 
 * @param {*} force 
 * @param {*} data 
 * @returns 
 */
export const export_inventory = (force = false, data) => {
    return async (dispatch, getState) => {

        dispatch(start_loading())
        try {
            const response = await documentService.export_shippingInvenctory(data)
            if (checkStatus(response)) {
                const blob = await response.data;
                saveAs(blob, 'shippinginvenctory.csv')
            }
            else dispatch(stop_loading())

        } catch (error) {
            console.log(error)
            dispatch(stop_loading())
        }
    }
}


/**
 * for shipping inventory
 */
export const fetch_inventory_waybill = (data, force = false) => {
    return async (dispatch, getState) => {
        try {
            const list = getState()?.shipping_inventory.shipping_inventory || []

            let searchOption = getState().document.searchOption

            if (data) {
                searchOption = {
                    ...searchOption,
                    ...data
                }
            }

            if (!force && Array.isArray(list) && list.length > 0) {
                return;
            }

            dispatch(set_search_option(searchOption));

            const inventory_response = await documentService.get_way_bill_list_shipping_inventory(searchOption)

            if (checkStatus(inventory_response)) {
                const body = await inventory_response.data;
                dispatch(set_shipping_inventory(body['docs']));
                dispatch(component_reducer.set_total_count(body['totalDocs'] || 0))
                //setTotalToLocation(body['totalDocs'] || 0)
            }

        } catch (error) {
            const size = (Array.isArray(error.errors) && error.errors.length > 0) ? error.errors.length - 1 : null
            dispatch(component_reducer.set_snack_bar_content({
                message: error?.message || (
                    size ? error?.errors[size].msg
                        :
                        'Oop something went wrong in getting data!'
                ),
                type: 'error'
            }))
        }
    }
}

/**
 * for search shipping inventory
 */
export const search_inventory_waybill = (force = false, bodyData, status) => {
    return async (dispatch, getState) => {
        try {
            const list = getState()?.shipping_inventory.shipping_inventory || []

            if (!force && Array.isArray(list) && list.length > 0) {
                return;
            }
            const inventory_response = await shippingInventoryService.search_shipping_inventory(bodyData)

            if (checkStatus(inventory_response)) {
                const body = await inventory_response.data;
                if (status === 'CREATED') {
                    dispatch(set_shipping_inventory(body['docs']));
                } else {
                    dispatch(set_destination_inventory(body['docs']));
                }
                dispatch(component_reducer.set_total_count(body['totalDocs'] || 0))
                //setTotalToLocation(body['totalDocs'] || 0)
            } else {
                dispatch(component_reducer.set_snack_bar_content({
                    message: 'Oop something went wrong!',
                    type: 'error'
                }))
            }

        } catch (error) {
            const size = (Array.isArray(error.errors) && error.errors.length > 0) ? error.errors.length - 1 : null
            dispatch(component_reducer.set_snack_bar_content({
                message: error?.message || (
                    size ? error?.errors[size].msg
                        :
                        'Oop something went wrong in getting data!'
                ),
                type: 'error'
            }))
        }
    }
}

/**
 * for destination inventory
 */
export const fetch_destination_waybill = (data, force = false) => {
    return async (dispatch, getState) => {
        try {
            const list = getState()?.distinction_inventory.destination_inventory || []

            let searchOption = getState().document.searchOption

            if (data) {
                searchOption = {
                    ...searchOption,
                    ...data
                }
            }

            if (!force && Array.isArray(list) && list.length > 0) {
                return;
            }

            dispatch(set_search_option(searchOption));

            const inventory_response = await documentService.get_way_bill_list_for_destination_inventory(searchOption)

            if (checkStatus(inventory_response)) {
                const body = await inventory_response.data;
                dispatch(set_destination_inventory(body['docs']));
                dispatch(component_reducer.set_total_count(body['totalDocs'] || 0))
                //setTotalToLocation(body['totalDocs'] || 0)
            }

        } catch (error) {
            const size = (Array.isArray(error.errors) && error.errors.length > 0) ? error.errors.length - 1 : null
            dispatch(component_reducer.set_snack_bar_content({
                message: error?.message || (
                    size ? error?.errors[size].msg
                        :
                        'Oop something went wrong in getting data!'
                ),
                type: 'error'
            }))
        }
    }
}



/**
 * for sign confirmed waybill
 */
export const fetch_sign_confirmed_waybill = (force = false) => {
    return async (dispatch, getState) => {
        try {
            const list = getState()?.sign.query_list || []
            if (!force && Array.isArray(list) && list.length > 0) {
                return;
            }
            const response = await documentService.get_cargo_way_bill_list({
                status: WAYBILL_STATUS.SIGNED
            })

            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_sign_query_list(body['docs']));
                dispatch(component_reducer.set_total_count(body['totalDocs'] || 0))
                //setTotalToLocation(body['totalDocs'] || 0)
            }

        } catch (error) {
            const size = (Array.isArray(error.errors) && error.errors.length > 0) ? error.errors.length - 1 : null
            dispatch(component_reducer.set_snack_bar_content({
                message: error?.message || (
                    size ? error?.errors[size].msg
                        :
                        'Oop something went wrong in getting data!'
                ),
                type: 'error'
            }))
        }
    }
}

/**
 * for truck loading page
 * two button action , for >> and <<
 * @returns []
 * [0] the remaining items of array from incoming array
 * [1] the result array of checked items
 */

const first_table_rows = 'first_table_rows';
const second_table_rows = 'second_table_rows';

const filter_checked_item = (array = []) => {

    const tempArr = _.cloneDeep(array) || []
    const size = tempArr.length
    let i = 0
    let temp = []

    /**
     * remove checkedItem from table list and push to new array
     * set checked to false as default when the item is found
     * because the item will serve as new item in another array
     */
    for (; i < size; i++) {
        if (tempArr[i]?.checked === true) {
            const current = tempArr.splice(i, 1)[0]
            current.checked = false
            temp.push(current);
            i--;
        }
    }

    return [tempArr, temp]
}

export const forward_button_action = (waybill = null) => {
    return async (dispatch, getState) => {
        try {

            if (!waybill) {
                dispatch(component_reducer.set_snack_bar_content({
                    message: 'There is data to update',
                    type: 'error'
                }))
                return;
            }

            const second_table_list = getState().truck_loading[second_table_rows] || [];

            if (Array.isArray(second_table_list) && second_table_list.length > 0) {
                const searchList = second_table_list.filter(r => r?.waybillnumber === waybill?.waybillnumber)
                if (searchList.length > 0) {
                    dispatch(component_reducer.set_snack_bar_content({
                        message: 'Already existed!',
                        type: 'warning'
                    }))

                    return;
                }
            }


            dispatch(set_second_table_row([...second_table_list, waybill]))
            dispatch(set_able_to_confirm_status(true))

        } catch (error) {
            dispatch(component_reducer.set_snack_bar_content({
                message: error.message || 'Oop something went wrong!',
                type: 'error'
            }))
        }
    }
}


export const backward_button_action = () => {
    return async (dispatch, getState) => {
        try {

            const first_table_list = getState().truck_loading[first_table_rows] || [];
            const second_table_list = getState().truck_loading[second_table_rows] || [];

            if (Array.isArray(second_table_list) && second_table_list.length > 0) {

                const [remaining_array, checked_list] = filter_checked_item(second_table_list);

                /**
                 * when there is no selected data
                 */
                if (checked_list.length === 0) {
                    dispatch(component_reducer.set_snack_bar_content({
                        message: 'There is no selected data to delete. Please select at least 1 row.',
                        type: 'error'
                    }));

                    return;
                }
                else {
                    dispatch(set_first_table_row([...first_table_list, ...checked_list]));
                    dispatch(set_second_table_row(remaining_array));
                    dispatch(set_able_to_confirm_status(true));
                }
            }
            else
                dispatch(component_reducer.set_snack_bar_content({
                    message: 'There is no selected data to update',
                    type: 'error'
                }))

        } catch (error) {
            dispatch(component_reducer.set_snack_bar_content({
                message: error.message || 'Oop something went wrong!',
                type: 'error'
            }))
        }
    }
}

/**
 * confirm action button at truck loading page.
 * will check changed item from each table and 
 * request to batch udpdate.
 * @param {integer} confirm_for [0,1]
 *  [0] for cargo-loading 
 *  [1] for cargo-unloading
 * @param {way_id} selected_way for current cargo action way  
 * @returns 
 */
export const confirm_cargo_table = (confirm_for, selected_way, is_reopen = false) => {
    return async (dispatch, getState) => {
        try {

            /**
             * getting each table's data from app state
             */
            const first_table_list = getState().truck_loading[first_table_rows] || [];
            const second_table_list = getState().truck_loading[second_table_rows] || [];

            let first_status, second_status,
                first_optional_status = [],
                cargo_action

            if (!Array.isArray(first_table_list) || !Array.isArray(second_table_list)) {
                dispatch(component_reducer.set_snack_bar_content({
                    message: 'Invalid data list',
                    type: 'error'
                }))

                return;
            }

            switch (confirm_for) {
                case 0:
                    first_status = WAYBILL_STATUS.LOADED
                    second_status = WAYBILL_STATUS.CREATED

                    first_optional_status = [
                        WAYBILL_STATUS.DELIVERED,
                        WAYBILL_STATUS.UNLOADED,
                        WAYBILL_STATUS.SIGNED
                    ]

                    if (is_reopen) {
                        first_optional_status.push(WAYBILL_STATUS.CONFIRMED)
                    }

                    cargo_action = 'loading'

                    break;

                case 1:
                    first_status = WAYBILL_STATUS.UNLOADED
                    second_status = WAYBILL_STATUS.CONFIRMED
                    cargo_action = 'unloading'

                    break;

                default:
                    break;
            }

            const filtered_first_table = getMatchedItem(first_table_list, first_status, first_optional_status);
            const filtered_second_table = getMatchedItem(second_table_list, second_status, first_optional_status);

            if (filtered_first_table.length > 0 || filtered_second_table.length > 0) {

                const response = await shippingInventoryService.common_cargo_action({
                    selected_way: selected_way,
                    waybill_list: filtered_second_table,
                    undo_waybill_list: filtered_first_table,
                    cargo_action: cargo_action
                })

                if (checkStatus(response)) {
                    const body = await response.data
                    dispatch(component_reducer.set_snack_bar_content({
                        message: body['message'] || `Successfully updated data`,
                        type: 'success'
                    }))

                    dispatch(set_able_to_confirm_status(false, second_status, first_status));

                }
            }

            else {
                dispatch(component_reducer.set_snack_bar_content({
                    message: 'Please select at least one item',
                    type: 'error'
                }))
            }
        } catch (error) {
            const size = (Array.isArray(error.errors) && error.errors.length > 0) ? error.errors.length - 1 : null
            dispatch(component_reducer.set_snack_bar_content({
                message: error?.message || (
                    size ? error?.errors[size].msg
                        :
                        'Oop something went wrong!'
                ),
                type: 'error'
            }))
        }
    }
}


/**
 * 
 * @param {*} selected_way way_id
 * @param {*} type first action status of truck loading
 * @returns 
 */
export const common_cargo_action = (selected_way, type) => {
    return async (dispatch, getState) => {
        try {

            /**
             * route is the api endpoint
             */
            let field, route
            if (!selected_way)
                throw new Error('Invalid Way Id, there will be no action. Please check the data');

            switch (type) {
                /**
                 * cargo loading
                 */
                case WAYBILL_STATUS.CREATED:
                    field = first_table_rows
                    route = 'load-way-bill'
                    break;

                /**
                 * undo cargo loading
                 */
                case WAYBILL_STATUS.LOADED:
                    field = second_table_rows
                    route = 'undo-load-way-bill'
                    break;

                /**
                 * cargo unload
                 */
                case WAYBILL_STATUS.CONFIRMED:
                    field = first_table_rows
                    route = 'unload-way-bill'
                    break;

                /**
                 * undo cargo unload
                 */
                case WAYBILL_STATUS.UNLOADED:
                    field = second_table_rows
                    route = 'undo-unload-way-bill'
                    break;

                default:
                    break;
            }

            const list = getState().truck_loading[field] || []

            if (Array.isArray(list) && list.length > 0) {
                let checkList = []
                const size = list.length
                let i = 0
                for (; i < size; i++) {
                    const temp = list[i]
                    if (temp?.checked)
                        checkList.push(temp?._id)
                }

                const payload = {
                    waybill_id_list: checkList,
                    selected_way: selected_way,
                }

                if (checkList.length > 0) {
                    const response = await shippingInventoryService.common_waybill_action(route, payload);

                    if (checkStatus(response)) {
                        const body = await response.data

                        dispatch(component_reducer.set_snack_bar_content({
                            message: body['message'] || `Successfully loaded ${checkList.length} records`,
                            type: 'success'
                        }))

                        if ([WAYBILL_STATUS.CREATED, WAYBILL_STATUS.LOADED].includes(type))
                            dispatch(action_fetch_cargo_loading_data(selected_way));
                        else
                            dispatch(action_fetch_cargo_unloading_data(selected_way));

                    }
                }
                else
                    dispatch(component_reducer.set_snack_bar_content({
                        message: 'There is no selected waybill',
                        type: 'warning'
                    }))
            }

            else
                dispatch(component_reducer.set_snack_bar_content({
                    message: 'There is no waybill or check the list',
                    type: 'warning'
                }))
        } catch (error) {
            const size = (Array.isArray(error.errors) && error.errors.length > 0) ? error.errors.length - 1 : null
            dispatch(component_reducer.set_snack_bar_content({
                message: error?.message || (
                    size ? error?.errors[size].msg
                        :
                        'Oop something went wrong!'
                ),
                type: 'error'
            }))
        }
    }
}

export const action_recall_way = (way_id, way_number) => {
    return async (dispatch, getState) => {
        try {
            const response = await shippingInventoryService.recall_way(way_id);

            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(component_reducer.set_snack_bar_content({
                    message: body?.message || `Successfully recall way : ${way_number}`,
                    type: 'success'
                }))

                dispatch(action_fetch_way_list(true));
            }

        } catch (error) {
            const size = (Array.isArray(error.errors) && error.errors.length > 0) ? error.errors.length - 1 : null
            dispatch(component_reducer.set_snack_bar_content({
                message: error?.message || (
                    size ? error?.errors[size].msg
                        :
                        'Oop something went wrong in getting data!'
                ),
                type: 'error'
            }))
        }
    }
}