import {
    set_error_message,
    start_loading,
    stop_loading,
    
    set_item_types,
    set_express_types,
    set_payment_methods,
    set_problem_types,
    set_shipping_mode,
    set_collection_type
} from '../reducer.information';
import { informationservice } from '../../service';
import { checkStatus } from '../util';
import { component_reducer } from '..';

export const action_fetch_item_types = (force = false) => {
    return async (dispatch, getState) => {

        const result = getState()?.information.itemtypes || []
        /**
         * if data exist() 
         * do nothing and return
         */
        if (!force && Array.isArray(result) && result.length > 0) return;

        dispatch(start_loading());
        try {
            const response = await informationservice.getItemTypes(force);
            if (checkStatus(response)) {
                const body = await response.data
                dispatch(set_item_types(body));
            }
            else dispatch(stop_loading())
        } catch (error) {
            dispatch(set_error_message(error.messsage));
        }
    }
}

export const action_fetch_express_types = (force = false) => {
    return async (dispatch, getState) => {
        const result = getState()?.information.expresstypes || []
        /**
         * if data exist() 
         * do nothing and return
         */
        if (!force && Array.isArray(result) && result.length > 0) return;

        dispatch(start_loading());
        try {
            const response = await informationservice.getExpressTypes(force);
            if (checkStatus(response)) {
                const body = await response.data;

                dispatch(set_express_types(body));
            }
            else dispatch(stop_loading());
        } catch (error) {
            dispatch(set_error_message(error.messsage))
        }
    }
}

export const action_fetch_shipping_modes = (force = false) => {
    return async (dispatch, getState) => {
        const result = getState()?.information.shipping_modes || []
        /**
         * if data exist()
         * do nothing and return
         */
        if (!force && Array.isArray(result) && result.length > 0) return;

        dispatch(start_loading());
        try {
            const response = await informationservice.getShippingMode(force);
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_shipping_mode(body));
            }
            else dispatch(stop_loading());
        } catch (error) {
            dispatch(set_error_message(error.messsage));
        }
    }
}

export const action_fetch_payment_method = (force = false) => {
    return async (dispatch, getState) => {
        const result = getState()?.information.payment_methods || []
        /**
         * if data exist() 
         * do nothing and return
         */
        if (!force && Array.isArray(result) && result.length > 0) return;

        dispatch(start_loading());
        try {
            const response = await informationservice.getPaymentMethod(force);
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_payment_methods(body));
            }
            else dispatch(stop_loading());
        } catch (error) {
            dispatch(set_error_message(error.messsage))
        }
    }
}

export const action_fetch_problem_type = (force = false) => {
    return async (dispatch, getState) => {
        dispatch(start_loading());
        try {
            const result = getState()?.information.problem_types || []
            /**
             * if data exist() 
             * do nothing and return
             */
            if (!force && Array.isArray(result) && result.length > 0) return;

            const response = await informationservice.getProblemTypes(force);
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_problem_types(body));
            }
            else dispatch(stop_loading());
        } catch (error) {
            dispatch(set_error_message(error.messsage))
        }
    }
}

export const action_fetch_collection_type = (force = false) => {
    return async (dispatch, getState) => {
        try {
            const response = await informationservice.get_collection_type(force);
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_collection_type(body || []));
            }
        } catch (error) {
            dispatch(set_error_message(error.messsage));
        }
    }
}

export const action_create_collection_type = (data) => {
    return async (dispatch, getState) => {
        try {
            const response = await informationservice.create_collection_type(data);
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(action_fetch_collection_type(true));
                dispatch(component_reducer.set_snack_bar_content({
                    message: body.message || 'successfully_created',
                    type: 'success'
                }))
            } else {
                dispatch(component_reducer.set_snack_bar_content({
                    message: 'Oop something went wrong!',
                    type: 'error'
                }))
            }
        } catch (error) {
            const size = (Array.isArray(error.errors) && error.errors.length > 0) ? error.errors.length : null
            dispatch(component_reducer.set_snack_bar_content({
                message: error?.message || (
                    size ? error?.errors[size - 1].msg
                        :
                        'Oop something went wrong!'
                ),
                type: 'error'
            }))
        }
    }
}

/**
 * deleting item type
 */
export const deleteItem = (item_id)=> {
    return async(dispatch,getState)=> {
        try{
            if(!item_id) return;
            const response = await informationservice.delete_item_type(item_id);
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(action_fetch_item_types(true));
                dispatch(component_reducer.set_snack_bar_content({
                    message: body.message || 'Successfully deleted!',
                    type: 'success'
                }))
            } else {
                dispatch(component_reducer.set_snack_bar_content({
                    message: 'Oop something went wrong!',
                    type: 'error'
                }))
            }
        }catch(error) {
            const size = (Array.isArray(error.errors) && error.errors.length > 0) ? error.errors.length : null
            dispatch(component_reducer.set_snack_bar_content({
                message: error?.message || (
                    size ? error?.errors[size - 1].msg
                        :
                        'Oop something went wrong!'
                ),
                type: 'error'
            }))
        }
    }
}


/**
 * deleting problem type
 */
 export const deleteProblemType = (_id)=> {
    return async(dispatch,getState)=> {
        try{
            if(!_id) return;
            console.log(_id)
            const response = await informationservice.deleteProblemType(_id);
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(action_fetch_problem_type(true));
                dispatch(component_reducer.set_snack_bar_content({
                    message: body.message || 'Successfully deleted!',
                    type: 'success'
                }))
            } else {
                dispatch(component_reducer.set_snack_bar_content({
                    message: 'Oop something went wrong!',
                    type: 'error'
                }))
            }
        }catch(error) {
            const size = (Array.isArray(error.errors) && error.errors.length > 0) ? error.errors.length : null
            dispatch(component_reducer.set_snack_bar_content({
                message: error?.message || (
                    size ? error?.errors[size - 1].msg
                        :
                        'Oop something went wrong!'
                ),
                type: 'error'
            }))
        }
    }
}