export const types = {
     START_LOADING: 'START_LOADING',
     STOP_LOADING: 'STOP_LOADING',
     SET_WAYBILL_LIST: 'SET_WAYBILL_LIST',
     SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
     SET_INDEX: 'SET_INDEX',

     /** tracking management */
     SET_TRACKING_LIST: [],
}

const initialState = {
     isLoading: false,
     errorMessage: '',

     index: 0,
     waybill_list: null,

     /** tracking management */
     tracking_list: []

}

const Index = (state = initialState, action) => {
     switch (action.type) {
          case types.SET_WAYBILL_LIST:
               return {
                    ..._getCommonState(state),
                    waybill_list: action.payload,
               }
          case types.SET_INDEX:
               return {
                    ..._getCommonState(state),
                    index: action.payload,
               }
          case types.SET_TRACKING_LIST:
               return {
                    ..._getCommonState(state),
                    tracking_list: action.payload,
               }
          case types.START_LOADING:
               return {
                    ..._getCommonState(state),
                    isLoading: true,
               }
          case types.STOP_LOADING:
               return {
                    ..._getCommonState(state),
                    isLoading: false,
               }
          case types.SET_ERROR_MESSAGE:
               return {
                    ..._getCommonState(state),
                    errorMessage: action.payload
               }
          default:
               return state;
     }
}

const _getCommonState = (state) => (
     {
          ...state,
          isLoading: false,
          errorMessage: ''
     }
);

/**
 * Action
 */
export const set_waybill_list = (data = []) => {
     return {
          type: types.SET_WAYBILL_LIST,
          payload: data,
     }
}

export const set_index = (index) => {
     return {
          type: types.SET_INDEX,
          payload: index,
     }
}

export const start_loading = () => {
     return {
          type: types.START_LOADING
     }
}

export const stop_loading = () => {
     return {
          type: types.STOP_LOADING
     }
}

export const set_error_message = (message = '') => {
     return {
          type: types.SET_ERROR_MESSAGE,
          payload: message
     }
}

/** Tracking list */
export const set_tracking_list = (data = []) => {
     return {
          type: types.SET_TRACKING_LIST,
          payload: data
     }
}

export default Index;