const types = {
  SET_COUNT_DATA: "SET_COUNT_DATA",
  START_LOADING: "START_LOADING",
  STOP_LOADING: "STOP_LOADING",
  SET_ERROR_MESSAGE: "SET_ERROR_MESSAGE",
  SET_ALL_COUNT: "SET_ALL_COUNT",
  SET_MONTHLY_DATA: "SET_MONTHLY_DATA",
};

const initail_state = {
  monthly_data: {},
  count_data: {},
  all_count: [],
  isLoading: false,
  errorMessage: "",
};

const _getCommonState = (state) => {
  return {
    ...state,
    isLoading: false,
    errorMessage: "",
  };
};

/**
 * Reducer
 */
const Index = (state = initail_state, action) => {
  switch (action.type) {
    case types.SET_COUNT_DATA:
      return {
        ..._getCommonState(state),
        count_data: action.payload,
      };
    case types.SET_ALL_COUNT:
      return {
        ..._getCommonState(state),
        all_count: action.payload,
      };
    case types.SET_MONTHLY_DATA:
      return {
        ..._getCommonState(state),
        monthly_data: action.payload,
      };
    case types.START_LOADING:
      return {
        ..._getCommonState(state),
        isLoading: true,
      };
    case types.STOP_LOADING:
      return {
        ..._getCommonState(state),
        isLoading: false,
      };
    case types.SET_ERROR_MESSAGE:
      return {
        ..._getCommonState(state),
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

/**
 * Action
 */

export const set_count_data = (data = []) => ({
  type: types.SET_COUNT_DATA,
  payload: data,
});

export const set_all_count = (data = []) => ({
  type: types.SET_ALL_COUNT,
  payload: data,
});

export const set_monthly_data = (data = {}) => ({
  type: types.SET_MONTHLY_DATA,
  payload: data,
});

export const start_loading = () => ({
  type: types.START_LOADING,
});

export const stop_loading = () => ({
  type: types.STOP_LOADING,
});

export const set_error_message = (message = "") => ({
  type: types.SET_ERROR_MESSAGE,
  payload: message,
});

export default Index;
