import { combineReducers } from "redux";

import user from "./reducer.user";

/** To make reducer persistance */
import { persistReducer } from "redux-persist";

/** To store local storage */
import storage from "redux-persist/lib/storage";
import information, * as information_reducer from "./reducer.information";
import option from "./reducer.option";
import sign from "./reducer.sign";
import customer_service from "./reducer.customer_service";
import dashboard from "./reducer.dashboard";

import tabbar, * as tabBar_reduer from "./reducer.tabbar";

import document, * as document_reducer from "./reducer.document";

import system, * as system_reducer from "./reducer.system";
import component, * as component_reducer from "./reducer.components";

import finance, * as finance_reducer from "./reducer.finance";

import location, * as location_reducer from "./reducer.location";

import scanning, * as scanning_reducer from "./reducer.scanning";
/**
 * actions
 */
import * as Document_Action from "./action.document";
import * as User_Action from "./action.user";
import * as Information_Action from "./action.information";
import * as System_Action from "./action.system";
import * as Option_Action from "./action.option";
import * as Cargo_Division_Action from "./action.cargo_division_center";
import * as Finance_Action from "./action.finance";
import * as Location_Action from "./action.location";
import * as Scanning_Action from "./action.scanning";

const WAYBILL_STATUS = {
  CREATED: "CREATED",
  LOADED: "LOADED",
  UNDO_LOADED: "UNDO_LOADED",
  CONFIRMED: "CONFIRMED",
  UNLOADED: "UNLOADED",
  DELIVERED: "DELIVERED",
  SIGNED: "SIGNED",
};

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["tabbar"],
};

const rootReducer = combineReducers({
  user: user,
  component: component,
  system: system,
  information: information,
  option: option,
  document: document,
  tabbar: tabbar,
  sign: sign,
  finance: finance,
  location: location,
  scanning: scanning,
  customer_service: customer_service,
  dashboard: dashboard,
});

export default persistReducer(persistConfig, rootReducer);

export {
  User_Action,
  Information_Action,
  System_Action,
  Option_Action,
  Document_Action,
  Cargo_Division_Action,
  Finance_Action,
  Location_Action,
  Scanning_Action,
  /**
   * reducers
   */
  tabBar_reduer,
  document_reducer,
  component_reducer,
  system_reducer,
  finance_reducer,
  location_reducer,
  scanning_reducer,
  information_reducer,
  WAYBILL_STATUS,
};
