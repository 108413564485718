import { getPaginationQuery, httpService } from '.';


/**
 * Branch maintenance
 */

export async function getBranch({
    branches = []
}) {
    let url = `/branches/getbranch?`

    if (Array.isArray(branches) && branches.length > 0) {
        branches.forEach(b => {
            url += `branches[]=${b}&`
        })
    }

    return await httpService.get(url)

}

export async function getBranchTree() {
    return await httpService.get('/branch/get-branch-tree');
}

export async function getAllBranch(refresh = false) {
    let query = '/branch/get-all'
    if (refresh) query += '-refresh'
    return await httpService.get(query);
}

export async function getDestination() {
    return await httpService.get('/destinations/getdestination');
}

/**
 * address book
 */
export async function getAddressBooklist(refresh, data) {
    return await httpService.post(`/address-book/get-all${(refresh ? '-refresh?' : '?')}${getPaginationQuery()}`, data)
}

/**
 * other maintenance
 * 
 */
export async function getItemTypes(refresh) {
    return await httpService.get('/itemtypes/get-all' + (refresh ? '-refresh' : ''));
}

export async function getExpressTypes(refresh) {
    return await httpService.get('/shipping-type/get-all' + (refresh ? '-refresh' : ''));
}

export async function getShippingMode(refresh) {
    return await httpService.get('/shipping-mode/get-all' + (refresh ? '-refresh' : ''))
}

export async function getCarriers() {
    return await httpService.get('/carriers/getcarrier');
}

export async function getPaymentMethod(refresh) {
    return await httpService.get('/payment-type/get-all' + (refresh ? '-refresh' : ''));
}

export async function getProblemTypes(force = false) {
    return await httpService.get(`/problems/get-all${force ? '-refresh' : ''}`);
}

export async function getTrucks(refresh) {
    return await httpService.get('/truck/get-all' + (refresh ? '-refresh' : ''));
}

/**
 * post and create data
 */

/**
 * branch and destination
 */
export async function create_branch(data) {
    return await httpService.post('/branch/create', data);
}

export async function update_branch(data) {
    return await httpService.post('/branch/update', data)
}

export async function changeStatus(id, data) {
    return await httpService.patch(`/branch/${id}`, data)
}

export async function delete_branch(id) {
    return await httpService.delete(`/branch/${id}`)
}

export async function create_destination(data) {
    return await httpService.post('/destinations/createdestination', data);
}

export async function create_address_book(data) {
    return await httpService.post('/address-book/create', data)
}

export async function update_info_address_book(data) {
    return await httpService.patch('/address-book/update-info', data);
}

export async function update_address_address_book(data) {
    return await httpService.patch('/address-book/update-address', data);
}


export async function delete_test(id) {
    return id ? true : false
}

/**
 * CUSTOMER INFORMATION
 */
/** default is VIP */
export async function get_customer_list(type) {
    let query = '/customers'
    if (type && ['self', 'vip'].includes(type))
        query += `?type=${type}`
    return await httpService.get(query);
}

/**
 * creating VIP customer
 * @param {*} data 
 */
export async function create_vip_customer(data) {
    return await httpService.post('/customers/vip-customer-register', data);
}

/**
 * creating item type
 */

export async function create_item_type(data) {
    return await httpService.post('/itemtypes/create', data);
}

/**
 * updating item type
 */
export async function update_item_type(data) {
    return await httpService.patch(`/itemtypes/update`, data);
}

/**
 * 
 * @param {*} _id
 * @returns 
 */
export async function delete_item_type(_id) {
    return await httpService.delete(`/itemtypes/${_id}`);
}

/**
 * creating trucks
 */
export async function create_truck(data) {
    return await httpService.post('/truck/create', data);
}
/**
 * create express type
 */
export async function create_express_type(data) {
    return await httpService.post('/shipping-type/create', data);
}

/**
 * update express type
 */
export async function update_expredd_type(data) {
    return await httpService.patch(`/shipping-type/update`, data);
}

/**
 * create shipping mode
 */
export async function create_shipping_mode(data) {
    return await httpService.post('/shipping-mode/create', data)
}

/**
 * updating shipping mode
 */
export async function update_shipping_mode(data) {
    return await httpService.patch(`/shipping-mode/update`, data);
}

/**
 * creating payment type
 */
export async function create_new_payment_type(data) {
    return await httpService.post('/payment-type/create', data);
}

/**
 * updating payment type
 */
export async function update_payment_type(data) {
    return await httpService.patch(`/payment-type/update`, data);
}

/**
 * create problem type
 */
export async function create_new_problem_type(data) {
    return await httpService.post('/problems/create', data);
}

/**
 * update
 * @param {*} data 
 * @returns 
 */
export async function update_problem_type(data) {
    return await httpService.patch('/problems/update', data);
}

/**
 * delete problem type
 * @param {*} _id 
 * @returns 
 */
export async function deleteProblemType(_id) {
    return await httpService.delete(`/problems/${_id}`);
}

/**
 * truck list
 */
export async function get_truck_list() {
    return await httpService.get('/truck-and-way/truck-list');
}

/**
 * employee
 */
export async function get_employee_list(refresh) {
    let query = '/employee/get-all'
    if (refresh)
        query += '-refresh'

    return await httpService.get(query);
}

export async function get_sale_employee_option() {
    return await httpService.post('/employee/get-sale-employee-option');
}

export async function create_employee(data) {
    return await httpService.post('/employee/create', data);
}

export async function get_employee_option() {
    return await httpService.get('/employee/get-option');
}

/**
 * position
 */
export async function get_position_opton() {
    return await httpService.get('/position/get-option');
}

export async function get_position(force) {
    return await httpService.get(`/position/get-all${force ? '-refresh' : ''}`);
}

export async function create_position(data) {
    return await httpService.post('/position/create', data);
}

/**
 * collection
 */
export async function get_collection_type(force) {
    return await httpService.get(`/collection-type/get-all${force ? '-refresh' : ''}`);
}

export async function create_collection_type(data) {
    return await httpService.post('/collection-type/create', data);
}