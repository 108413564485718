import {
    start_loading,
    stop_loading,

    set_itemtype_list,
    set_region_list,
    set_township_list,

    set_payment_type_list,
    set_shipping_type_list,
    set_shipping_mode_list,

    set_scan_type_option,
    set_problem_option,
    set_payment_group,
    set_collection_type,

} from '../reducer.option'
import { financeService,  optionService } from '../../service';
import { checkStatus } from '../util';
import { component_reducer } from '..';

/**
 * region option list
 */
export const action_fetch_region_option = (force = false) => {
    return async (dispatch, getState) => {
        const region = getState()?.option.region_list || []
        if (!force && Array.isArray(region) && region.length > 0) {
            return;
        }
        dispatch(start_loading());
        try {
            const response = await optionService.OptionRegion();
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_region_list(body))
            }
            else dispatch(stop_loading())
        } catch (error) {
            console.log(error)
            dispatch(stop_loading())
        }
    }
}

/**
 * township option list
 */
export const action_fetch_township_option = (district) => {
    return async (dispatch, getState) => {
        dispatch(start_loading());
        dispatch(set_township_list([]))
        if (district)
            try {
                const response = await optionService.OptionTownship(district);
                if (checkStatus(response)) {
                    const body = await response.data;
                    dispatch(set_township_list(body))
                }
                else dispatch(stop_loading())
            } catch (error) {
                console.log(error)
                dispatch(stop_loading())
            }
    }
}

/**
 * itemtype option list
 */
export const action_fetch_itemtype_option = (force = false) => {
    return async (dispatch, getState) => {
        const itemtype_list = getState()?.option.itemtype_list || []
        if (!force && Array.isArray(itemtype_list) && itemtype_list.length > 0) {
            return;
        }
        try {
            const response = await optionService.OptionItemType();
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_itemtype_list(body))
            }

        } catch (error) {
            dispatch(stop_loading())
        }
    }
}

/**
 * payment type option list
 */
export const action_fetch_paymenttypes_option = (force = false) => {
    return async (dispatch, getState) => {
        const payment_type_list = getState()?.option.payment_type_list || []
        if (!force && Array.isArray(payment_type_list) && payment_type_list.length > 0) {
            return;
        }

        try {
            const response = await optionService.OptionPaymentType();
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_payment_type_list(body))
            }

        } catch (error) {
            console.log(error)
            dispatch(stop_loading())
        }
    }
}

/**
 * payment group option list
 */
export const action_fetch_paymentgroup = (force = false) => {
    return async (dispatch, getState) => {
        const payment_group_list = getState()?.option.payment_group || [];
        if (!force && Array.isArray(payment_group_list) && payment_group_list.length > 0) {
            return;
        }
        dispatch(start_loading());
        try {
            const response = await optionService.OptionPaymentGroup();
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_payment_group(body));
            } else {
                dispatch(stop_loading())
            }
        } catch (error) {
            console.log(error)
            dispatch(stop_loading())
        }
    }
}

/**
 * express type option list
 * expresstype = shipping type
 * super, normal , fast shipping etc
 */
export const action_fetch_shippingtype_option = (force = false) => {
    return async (dispatch, getState) => {
        const shipping_type_list = getState()?.option.shipping_type_list || []
        if (!force && Array.isArray(shipping_type_list) && shipping_type_list.length > 0) {
            return;
        }
        try {

            let option = getState().option?.shipping_type_list
            if (Array.isArray(option) && option.length) return;

            const response = await optionService.OptionExpressType();
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_shipping_type_list(body))
            }

        } catch (error) {
            console.log(error)
            dispatch(stop_loading())
        }
    }
}

/**
 * shipping mode
 * by air , by land , by marine etc..
 */
export const action_fetch_shipping_mode_option = (force = false) => {
    return async (dispatch, getState) => {
        const shipping_mode_list = getState()?.option.shipping_mode_list || []
        if (!force && Array.isArray(shipping_mode_list) && shipping_mode_list.length > 0) {
            return;
        }
        dispatch(start_loading());
        try {
            const response = await optionService.OptionShippingMode();
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_shipping_mode_list(body))
            }
            else dispatch(stop_loading())
        } catch (error) {
            console.log(error)
            dispatch(stop_loading())
        }
    }
}

/**
 * scan type option list
 */

export const action_fetch_scan_type_option = (force = false) => {
    return async (dispatch, getState) => {
        const scan_option = getState()?.option?.scantype_option || []
        if (!force && Array.isArray(scan_option) && scan_option.length > 0) {
            return;
        }

        try {
            const response = await optionService.OptionScanTypelist()
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_scan_type_option(body || []));
            }
        } catch (error) {
            console.log(error);
        }
    }
}

/**
 * problem
 */
export const action_fetch_problem_option = (force = false) => {
    return async (dispatch, getState) => {
        const problem = getState?.option?.problem_type_list || []
        if (!force && Array.isArray(problem) && problem.length > 0) {
            return;
        }

        try {
            const response = await optionService.OptionProblemType()
            if (checkStatus(response)) {
                const body = await response.data
                dispatch(set_problem_option(body || []))
            }

        } catch (error) {
            console.log(error.message)
        }
    }
}

/**
 * options for finance 
 */

export const action_fetch_collection_type_option = (force = false) => {
    return async (dispatch, getState) => {
        try {
            const list = getState?.option?.collectiontype_list || []

            if (!force && Array.isArray(list) && list.length > 0) {
                return;
            }

            const response = await financeService.getCollectionOption();
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_collection_type(body || []))
            }

        } catch (error) {
            console.log(error);
            dispatch(component_reducer.set_snack_bar_content({
                message: error.message,
                type: 'error'
            }))
        }
    }
}
