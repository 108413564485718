import { getPaginationQuery, httpService } from '.';

/**
 * 
 * @param {*} url 
 * @param {*} waybill_number 
 * @returns 
 */
export async function scanning(url, {
    waybill_number,
    ...rest
}) {
    return await httpService.post(`/scan/${url}/${waybill_number}`, { ...rest });
}

export async function importFile(scanType, data) {
    return await httpService.post(`/scan/import-waybill-number/${scanType}`, data,{
        headers: { "Content-Type": "application/json" },
    })
}

/**
 * get scanned record before confirmed
 * @param {*} field 
 */
export async function getScannedRecord(field) {
    return await httpService.get(`/scan/get-for/${field}?${getPaginationQuery()}`)
}

/**
 * 
 * {
 *  waybill_number : [],
 *  status: RECEIVE | DELIVER | SIGN | PROBLEM
 * }
 * @param {*} data 
 * @returns 
 */
export async function confirmedRecord(data) {
    return await httpService.post(`/scan/confirm`, data);
}

export async function deleteRecord(data) {
    return await httpService.post(`/scan/delete`, data);
}