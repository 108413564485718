import i18n from 'i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

import * as lang from './lang';

/**
 *  TODO tsa - anyone - if you want to add some value or keyword,plz notice and sort the value alphabetically
 *  use this link 
 *  easy link to sort json :  https://novicelab.org/jsonabc/
 */

export const FONT_CN = 'zh'
export const FONT_EN = 'en'

export const EN = {
    name: 'English',
    value: FONT_EN
}

export const ZH = {
    name: '中文',
    value: FONT_CN
}

export const LANGUAGES = [EN, ZH]

i18n.use(LanguageDetector)
    .init({
        resources: {
            'en': {
                translation: {
                    "header": {
                        "title_text": "Warehouse Tracking System"
                    },
                    "sidebar": lang.sidebar_en,
                    "waybill_entry": lang.waybill_entry_en,
                    "scanning": lang.scanning_en,
                    /**
                     * information
                     */
                    "information_other_item_type": lang.information_other_item_type_en,
                    "information_other_express_type": lang.information_other_express_type_en,
                    "information_other_carrieer": lang.information_other_carrieer_en,
                    "informtaion_other_payment_method": lang.information_other_payment_method_en,
                    "information_employee_maintenance": lang.information_employee_maintenance_en,
                    "information_branch": lang.information_branch_en,
                    "information_other_shipping_mode": lang.information_other_shipping_mode_en,
                    "information_other_currency": lang.information_other_currency_en,
                    "information_other_truck": lang.information_other_truck_en,
                    "position_management": lang.posistion_management_en,
                    "collection_management": lang.collection_management_en,
                    "address_book": lang.address_book_en,

                    /**
                     * Announcement
                     */
                    "announcement_list": lang.announcement_list_en,
                    "announcement_maintain": lang.announcement_maintain_en,
                    /**
                     * Sign
                     */
                    "sign": lang.sign_en,
                    /**
                     * Finance
                     */
                    "finance": lang.finance_en,

                    /**
                     * system
                     */
                    "system_user_management": lang.system_user_management_en,
                    "system_role_management": lang.system_role_management_en,

                    /** formik */
                    "formik": lang.formik_en,

                    /**
                     * common
                     */
                    "branch": "branch",
                    "distination": "destination",
                    "country": "country",
                    "region": "region",
                    "district": "district",
                    "township": "township",
                    "submit": "submit",
                    "cancel": "cancel",
                    "upload_file": "Upload File",
                    "add": "Add",
                    "refresh": "Refresh",
                    "close": "Close",
                    "clear": "Clear",
                    "search": "Search",
                    "update": "Update",
                    "delete": "Delete",
                    "reset": "Reset",
                    "print": "Print",
                    "export": "Export",
                    "select": "Select",
                    "updated_at": "Updated At",
                    "created_at": "Created At",
                    "updated_by": "Updated By",
                    "created_by": "Created By",
                    "no": "No.",
                    "truck_no": "truck_no",
                    "type": "type",
                    "size": "size",
                    "-": "-",
                    "open": "Open",
                    "confirm": "Confirm",
                    "confirm_delete": "Are you sure want to delete",
                    "name": "Name",
                    "phonenumber": "phonenumber",
                    "date": "Date",
                    "import": "import",
                    "is_required": " is required!",
                    "nodata": "nodata",
                    "": "",
                    "edit": "Edit",
                    "en": "Name [EN]",
                    "中文": "Name [中文]",
                    "remark": "Remark"
                }
            },

            'zh': {
                translation: {
                    "header": {
                        "title_text": "物流管理系统"
                    },
                    "sidebar": lang.sidebar_zh,
                    "waybill_entry": lang.waybill_entry_zh,
                    "scanning": lang.scanning_zh,

                    /**
                     * information
                     */
                    "information_other_item_type": lang.information_other_item_type_zh,
                    "information_other_express_type": lang.information_other_express_type_zh,
                    "information_other_carrieer": lang.information_other_carrieer_zh,
                    "informtaion_other_payment_method": lang.information_other_payment_method_zh,
                    "information_employee_maintenance": lang.information_employee_maintenance_zh,
                    "information_branch": lang.information_branch_zh,
                    "information_other_shipping_mode": lang.information_other_shipping_mode_zh,
                    "information_other_currency": lang.information_other_currency_zh,
                    "information_other_truck": lang.information_other_truck_zh,
                    "position_management": lang.posistion_management_zh,
                    "collection_management": lang.collection_management_zh,
                    "address_book": lang.address_book_zh,
                    /**
                     * Announcement
                     */
                    "announcement_list": lang.announcement_list_zh,
                    "announcement_maintain": lang.announcement_maintain_zh,

                    /**
                     * Sign
                     */
                    "sign": lang.sign_zh,


                    /**
                   * finance
                   */
                    "finance": lang.finance_zh,

                    /**
                    * system
                    */
                    "system_user_management": lang.system_user_management_zh,
                    "system_role_management": lang.system_role_management_zh,

                    /** formik */
                    "formik": lang.formik_zh,

                    /**
                     * common
                     */
                    "branch": "网点",
                    "distination": "目的地",
                    "country": "国",
                    "region": "省",
                    "district": "区",
                    "township": "市",
                    "submit": "提交",
                    "cancel": "取消",
                    "upload_file": "上传文件",
                    "add": "增加",
                    "refresh": "刷新",
                    "close": "关闭",
                    "clear": "清除",
                    "search": "搜索",
                    "update": "更新",
                    "delete": "删除",
                    "reset": "重启",
                    "print": "打印",
                    "export": "导出",
                    "select": "选择",
                    "updated_at": "更新时间",
                    "created_at": "创建时间",
                    "updated_by": "更新人",
                    "created_by": "创建人",
                    "no": "序号。",
                    "truck_no": "卡车号",
                    "type": "类型",
                    "size": "尺寸",
                    "-": "操作",
                    "confirm": "确定",
                    "open": "打开",
                    "confirm_delete": "确定删除吗？",
                    "name": "姓名",
                    "phonenumber": "电话",
                    "date": "日期",
                    "import": "进口",
                    "is_required": "是必须的!",
                    "nodata": "没有数据",
                    "": "",
                    "edit": "编辑",
                    "en": "姓名 [EN]",
                    "中文": "姓名 [中文]",
                    "remark": "评论"
                }
            },


        },
        debug: true,
        ns: ["translation"],
        defaultNS: 'translation',
        fallbackLng: FONT_EN,
        keySeparator: '.',
        interpolation: {
            formatSeparator: ','
        },
        react: {
            wait: true
        }
    })

export default i18n