import * as common from './type';
import _ from 'lodash';

const types = {
    SET_WAY_BILL_QUERY_LIST: "SET_WAY_BILL_QUERY_LIST",
    UPDATE_WAY_BILL_QUERY_LIST: "UPDATE_WAY_BILL_QUERY_LIST",
    
    SET_WAYBILL_LIST: 'SET_WAYBILL_LIST',

    SET_SENDER_INFO: "SET_SENDER_INFO",
    SET_RECEIVER_INFO: "SET_RECEIVER_INFO",
    SET_ADDRESSBOOK_INFO: "SET_ADDRESSBOOK_INFO",

    TOGGLE_TO_INDEX: "TOGGLE_TO_INDEX",
    TOGGLE_ALL_LIST: "TOGGLE_ALL_LIST",

    SET_CURRENT_WAYBILL: "SET_CURRENT_WAYBILL",
    SET_SEARCH_OPTION: "SET_SEARCH_OPTION",
    SET_DELIVERY_OPTION: "SET_DELIVERY_OPTION"
}

export const initialState = {
    isLoading: false,
    errorMessage: '',

    query_list: [],
    delivery_list: [],

    order_query_list: [],

    waybill_list: [],
    addressInfo: null,
    sender_info: null,
    receiver_info: null,

    currentWaybill: null,
    showEditDialog: false,

    delivery_option : [],

    searchOption: {},
}

const Index = (state = initialState, action) => {
    switch (action.type) {
        case common.START_LOADING:
            return {
                ..._getCommonState(state),
                isLoading: true
            }
        case common.STOP_LOADING:
            return {
                ..._getCommonState(state)
            }
        case common.SET_ERROR_MESSAGE:
            return {
                ..._getCommonState(state),
                errorMessage: action.payload
            }
        case types.SET_WAY_BILL_QUERY_LIST:
            return {
                ..._getCommonState(state),
                query_list: action.payload
            }
        case types.UPDATE_WAY_BILL_QUERY_LIST:
            return {
                ..._getCommonState(state),
                query_list: action.payload
            }
        case types.SET_DELIVERY_LIST:
            return {
                ..._getCommonState(state),
                delivery_list: action.payload
            }
        case types.SET_ORDER_QUERY_LIST:
            return {
                ..._getCommonState(state),
                order_query_list: action.payload
            }
        case types.SET_WAYBILL_LIST:
            return {
                ..._getCommonState(state),
                waybill_list: action.payload
            }
        case types.SET_SENDER_INFO:
            return {
                ..._getCommonState(state),
                sender_info: action.payload
            }
        case types.SET_RECEIVER_INFO:
            return {
                ..._getCommonState(state),
                receiver_info: action.payload
            }
        case types.SET_ADDRESSBOOK_INFO:
            return {
                ..._getCommonState(state),
                addressInfo: action.payload
            }

        case types.SET_DELIVERY_OPTION: 
            return {
                ..._getCommonState(state),
                delivery_option: action.payload
            }

        /**
         * TOGGLE TO INDEX
         */
        case types.TOGGLE_TO_INDEX:
            try {
                const { index, status, field } = action

                if (!field)
                    throw new Error('Invalid field for cargo action waybill list');

                const dump = _.cloneDeep(state[field]) || [];
                let dump_item = _.cloneDeep(dump[index]);

                dump_item = {
                    ...dump_item,
                    checked: status
                }

                dump[index] = dump_item

                const temp = {
                    ..._getCommonState(state)
                }

                temp[field] = dump;

                return temp

            } catch (error) {
                return {
                    ..._getCommonState(state),
                }
            }


        /**
         * TOGGLE ALL LIST
         */
        case types.TOGGLE_ALL_LIST:
            try {
                const { status, field } = action

                if (!field)
                    throw new Error('Invalid field for cargo action waybill list')

                const dump = _.cloneDeep(state[field]) || [];

                if (!Array.isArray(dump))
                    return { ..._getCommonState(state) }

                if (dump.length === 0)
                    return { ..._getCommonState(state) }

                let updated_list = dump.map(d => ({
                    ...d,
                    checked: status
                }))

                const temp = {
                    ..._getCommonState(state)
                }

                temp[field] = updated_list;

                return temp

            } catch (error) {
                return {
                    ..._getCommonState(state),
                }
            }

        case types.SET_CURRENT_WAYBILL:
            if (action.payload)
                return {
                    ..._getCommonState(state),
                    currentWaybill: action.payload,
                    showEditDialog: true,
                }
            else return {
                ..._getCommonState(state),
                currentWaybill: null,
                showEditDialog: false,
            }

        case types.SET_SEARCH_OPTION: {

            return {
                ..._getCommonState(state),
                searchOption: action.payload
            }
        }
        default:
            return state
    }
}

const _getCommonState = (state) => ({
    ...state,
    isLoading: false,
    errorMessage: '',
    // showEditDialog: false
})

export default Index;

export const start_loading = () => ({
    type: common.START_LOADING
})

export const stop_loading = () => ({
    type: common.STOP_LOADING
})

export const set_error_message = (message = '') => ({
    type: common.SET_ERROR_MESSAGE,
    payload: message
})

export const set_query_list = (data = []) => ({
    type: types.SET_WAY_BILL_QUERY_LIST,
    payload: data
})

export const update_query_list = (data = []) => ({
    type: types.UPDATE_WAY_BILL_QUERY_LIST,
    payload: data
})



export const set_delivery_list = (data = []) => ({
    type: types.SET_DELIVERY_LIST,
    payload: data
})

export const set_order_query = (data = []) => ({
    type: types.SET_ORDER_QUERY_LIST,
    payload: data
})

export const set_waybill_list = (data = []) => ({
    type: types.SET_WAYBILL_LIST,
    payload: data
})

export const set_sender_info = (data) => ({
    type: types.SET_SENDER_INFO,
    payload: data
})

export const set_receiver_info = (data) => ({
    type: types.SET_RECEIVER_INFO,
    payload: data
})

export const set_addressbook_info = (data) => ({
    type: types.SET_ADDRESSBOOK_INFO,
    payload: data
})


/**
 * update progress status to item 
 */
export const toggle_to_index = ({ index, status, field }) => ({
    type: types.TOGGLE_TO_INDEX,
    index,
    status,
    field
})

export const toggle_to_list = (status, field) => ({
    type: types.TOGGLE_ALL_LIST,
    status,
    field
})

/**
 * for editing waybill info
 */
export const set_current_waybill = (data) => ({
    type: types.SET_CURRENT_WAYBILL,
    payload: data
})

export const set_search_option = (data) => ({
    type: types.SET_SEARCH_OPTION,
    payload: data
})

export const set_delivery_option = (data = []) =>({
    type: types.SET_DELIVERY_OPTION,
    payload: data
})