import { httpService } from '.';
import { getPaginationQuery } from '.'

/**
 * waybill list raw
 */
export async function get_way_bill_list() {
    return await httpService.get(`/waybill/get-all?${getPaginationQuery()}`);
}

export async function get_waybill_by_number(waybill_no,status) {
    return await httpService.get(`/waybill/find-one/${waybill_no}?status=${status}`)
}

/**
 * requesting and canceling waybill number 
 */
export async function request_waybill_number() {
    return await httpService.get(`/waybill/request-waybill-number`);
}

export async function cancel_waybill_number(data) {
    return await httpService.post(`/waybill/cancel-waybill-number`, data)
}

export async function delete_waybill(id) {
    return await httpService.delete(`/waybill/${id}`);
}
/**
 * 
 */
export async function sign_confirmation(data) {
    return await httpService.post(`/waybill/sign-confirmation`, data);
}

/**
 * creating new way bill
 * @param {*} data 
 */
export async function create_waybill_doc(data) {
    return await httpService.post('/waybill/create', data);
}

/**
 * express tracking
 */

export async function get_by_waybill(waybill) {
    return await httpService.get(`/waybills/${waybill}`)
}

/**
 * waybill searching and filtering
 */
export async function search_and_filter(data) {
    return await httpService.post(`/waybill/search?${getPaginationQuery()}`, data)
}

export async function export_waybills() {

    return await httpService.get('/waybill/export-waybill', {
        responseType: 'blob',
    })
    // httpService.get(`/waybill/export-waybill`)
}


/**
 * Sign waybill
 */
export async function get_sign_waybill_option() {
    return await httpService.get('/waybill/sign-confirmation-waybill');
}

/**
 * Waybill Scan
 */
export async function get_wayill_scan(data) {
    return await httpService.get(`/scan/find/${data}`);
}

export async function get_wayill_all_scan(data) {
    return await httpService.post(`/scan/findall`,data);
}

/**
 * Waybill Logs
 */
export async function get_waybill_log() {
    return await httpService.get('/scan/latest_scan_logs');
}

/**
 * Get signed waybill
 */
export async function get_signed_waybill(data) {
    return await httpService.post(`/waybill/signed-waybill?`,data);
}
