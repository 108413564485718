export const START_LOADING = "START_LOADING"

export const STOP_LOADING = "STOP_LOADING"

export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE"

// For dynamic tabbar
export const ADD_TAB = 'ADD_TAB';
export const DEL_TAB = 'DEL_TAB';

/**
 * options
 */

export const SET_REGION_LIST = "SET_REGION_OPTION"

export const SET_DISTRICT_LIST = "SET_DISTRICT_OPTION"

export const SET_TOWNSHIP_LIST = "SET_TOWNSHIP_OPTION"

export const SET_ITEMTYPE_LIST = "SET_ITEMTYPE_LIST"

export const SET_PAYMENT_TYPE_LIST = "SET_PAYMENT_TYPE_LIST"

export const SET_SHIPPING_TYPE_LIST = "SET_SHIPPING_TYPE_LIST"

export const SET_SHIPPING_MODE_LIST = "SET_SHIPPING_MODE_LIST"

export const SET_PROBLEM_TYPE_LIST = "SET_PROBLEM_TYPE_LIST"

export const SET_SCAN_TYPE_OPTION = "SET_SCAN_TYPE_OPTION"

export const SET_TRUCK_OPTION = "SET_TRUCK_OPTION"

export const SET_PAYMENT_GROUP = 'SET_PAYMENT_GROUP'

export const SET_COLLECTION_TYPE = 'SET_COLLECTION_TYPE'

export const SET_POSTION_OPTION = 'SET_POSTION_OPTION'

export const SET_SIGN_WAYBILL = 'SET_SIGN_WAYBILL';