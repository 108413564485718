import { httpService } from "./";

export const get_count_data = async () => {
  return await httpService.get("/graph/get-count-data");
};

export const get_all_count = async () => {
  return await httpService.get("/graph/count-current-status");
};

export const get_monthly_data = async () => {
  return await httpService.get("/graph/get-monthly-data");
};
