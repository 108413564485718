import {
    set_error_message,
    start_loading,
    stop_loading,

    set_customer_list,
    set_address_book,
} from '../reducer.information';
import {
    set_search_option
} from '../reducer.document';
import { component_reducer } from '../'
import { informationservice } from '../../service';
import { checkStatus } from '../util'

export const action_fetch_customer = () => {
    return async (dispatch, getState) => {
        dispatch(start_loading());
        try {
            const response = await informationservice.get_customer_list('self');
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_customer_list(body.docs || []));
            }
            else dispatch(stop_loading())
        } catch (error) {
            dispatch(set_error_message(error.message))
        }
    }
}

/**
 * address book
 */
export const action_fetch_address_book = (force = false, payload = {}) => {
    return async (dispatch, getState) => {
        try {

            const data = getState()?.information?.address_books || []
            if (!force && Array.isArray(data) && data.length > 0) {
                return;
            }

            let searchOption = getState().document.searchOption

            searchOption = {
                ...searchOption,
                ...payload
            }
            dispatch(set_search_option(searchOption));

            const response = await informationservice.getAddressBooklist(force,searchOption);
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_address_book(body.docs || []));
                dispatch(component_reducer.set_total_count(body['totalDocs'] || 0));
                dispatch(set_search_option({}));
            }
            else {
                console.log('..here')
            }
        } catch (error) {
            dispatch(set_error_message(error.message))
        }
    }
}

export const action_create_address_book = (data) => {
    return async (dispatch) => {
        dispatch(start_loading())
        try {
            const response = await informationservice.create_address_book(data);
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(action_fetch_address_book(true));
                dispatch(component_reducer.set_snack_bar_content({
                    message: body.message || 'successfully_created',
                    type: 'success'
                }))
            } else {
                dispatch(component_reducer.set_snack_bar_content({
                    message: 'Oop something went wrong!',
                    type: 'error'
                }))
            }
        } catch (error) {
            dispatch(stop_loading());
            dispatch(component_reducer.set_snack_bar_content({
                message: error.errors[0].msg,
                type: 'error'
            }))
        }
    }
}

export const action_update_address_book = (data) => {
    return async (dispatch) => {
        dispatch(start_loading())
        try {
            const response = await informationservice.update_address_address_book(data);
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(action_fetch_address_book(true));
                dispatch(component_reducer.set_snack_bar_content({
                    message: body.message || 'successfully_updated',
                    type: 'success'
                }))
            } else {
                dispatch(component_reducer.set_snack_bar_content({
                    message: 'Oop something went wrong!',
                    type: 'error'
                }))
            }
        } catch (error) {
            dispatch(stop_loading());
            dispatch(component_reducer.set_snack_bar_content({
                message: error.errors[0].msg,
                type: 'error'
            }))
        }
    }
}
