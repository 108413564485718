import { component_reducer } from '..';
import { scanningService } from '../../service';
import {
    set_scan_field_data
} from "../reducer.scanning"
import { checkStatus } from "../util"

/**
 * 
 * @param {*} param0 
 * @param {*} force 
 * @returns 
 */
export const action_fetch_scanned_records = ({
    status = '',
    field = ''
}, force = false) => {

    return async (dispatch, getState) => {
        dispatch(component_reducer.start_loading_table());
        if (!status || !field)
            return
        try {

            const data = getState()?.scanning?.[field]
            if (!force && Array.isArray(data) && data.length > 0) {
                return;
            }
            const response = await scanningService.getScannedRecord(status);
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_scan_field_data(body?.docs || [], field))
                dispatch(component_reducer.set_total_count(body['totalDocs'] || 0))
                
            }
        } catch (error) {
            dispatch(component_reducer.set_snack_bar_content({
                message: error.message,
                type: 'error'
            }))
        }
        setTimeout(()=>{
            dispatch(component_reducer.stop_loading_table());
        },1000)
    }
}


function filterCheckedItem(data = []) {
    return data.filter(d => d.checked === true).map(d => d._id);
}
/**
 * 
 * @param {*} type 
 * @returns 
 */
export const action_change_records = (type, isDelete = false) => {
    return async (dispatch, getState) => {
        try {

            const data = getState()?.scanning?.[type?.field] || [];

            const payload = {
                waybill_list: filterCheckedItem(data),
                status: type?.status
            }

            let response;
            if (isDelete)
                response = await scanningService.deleteRecord(payload);

            else
                response = await scanningService.confirmedRecord(payload);

            if (checkStatus(response)) {
                const body = await response.data;
                
                dispatch(action_fetch_scanned_records(type, true));

                dispatch(component_reducer.set_snack_bar_content({
                    message: body.message,
                    type: 'success'
                }));
                
            }
        } catch (error) {

            dispatch(component_reducer.set_snack_bar_content({
                message: error?.message || (
                    Array.isArray(error?.errors) ?
                        error?.errors[0].msg
                        :
                        'Oop something went wrong in confirming records!'
                ),
                type: 'error'
            }))
        }
    }
}