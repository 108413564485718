import { httpService } from '.';

/**
 * get record list
 * @param {*} refresh 
 * @returns 
 */
export async function getCountry(refresh = false) {
    return await httpService.get('/country/get-all' + (refresh ? '-refresh' : ''))
}

export async function getRegion(refresh = false) {
    return await httpService.get('/region/get-all' + (refresh ? '-refresh' : ''))
}

export async function getDistrict(refresh = false) {
    return await httpService.get('/district/get-all' + (refresh ? '-refresh' : ''))
}

export async function getTownship(refresh = false) {
    return await httpService.get('/township/get-all' + (refresh ? '-refresh' : ''))
}

/**
 * 
 * create data
 * @param {*} data 
 * @returns 
 */
export async function createCountry(data) {
    return await httpService.post('/country/create', data)
}

export async function updateContry(data) {
    return await httpService.post('/country/update', data)
}

export async function createRegion(data) {
    return await httpService.post('/region/create', data)
}

export async function updateRegion(id, data) {
    return await httpService.patch(`/region/${id}`, data)
}

export async function createDistrict(data) {
    return await httpService.post('/district/create', data)
}

export async function updateDistrict(id,data) {
    return await httpService.patch(`/district/${id}`, data)
}

export async function createTownship(data) {
    return await httpService.post('/township/create', data)
}

export async function updateTownship(id,data) {
    return await httpService.patch(`/township/${id}`, data)
}

/**
 * import data
 * region,
 * district
 * township
 */
export async function importRegion(data) {
    return await httpService.post('/region/import', data);
}

export async function importDistrict(data) {
    return await httpService.post('/district/import', data);
}

export async function importTownship(data) {
    return await httpService.post('/township/import', data);
}