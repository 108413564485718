import { component_reducer } from '..';
import { documentService } from '../../service';
import {
    set_query_list,
    set_search_option,
    start_loading,
    stop_loading,
    update_query_list
} from "../reducer.document"
import { checkStatus } from "../util"

import { saveAs } from 'file-saver';

export const action_fetch_waybill_query_list = () => {
    return async (dispatch, getState) => {
        dispatch(start_loading())
        try {
            const response = await documentService.get_way_bill_list()
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_query_list(body.docs || []))
                //setTotalToLocation(body.totalDocs || 0)
                dispatch(component_reducer.set_total_count(body['totalDocs'] || 0))
            }
            else dispatch(stop_loading())
        } catch (error) {
            console.log(error)
            dispatch(stop_loading())
        }
    }
}


export const action_fliter_search_list = (data) => {
    return async (dispatch, getState) => {
        dispatch(start_loading())
        try {
            let searchOption = getState().document.searchOption
            
            if (data) {
                searchOption = {
                    ...searchOption,
                    ...data
                }
            }
            dispatch(set_search_option(searchOption));

            const response = await documentService.search_and_filter(searchOption)
            
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(update_query_list(body.docs || []))
                dispatch(component_reducer.set_total_count(body['totalDocs'] || 0))
            }
            else dispatch(stop_loading())
        } catch (error) {
            console.log(error)
            dispatch(stop_loading())
        }
    }
}

export const action_waybill_export_list = () => {
    return async (dispatch, getState) => {

        dispatch(start_loading())
        try {

            const response = await documentService.export_waybills()
            if (checkStatus(response)) {
                const blob = await response.data;
                saveAs(blob, 'users.csv')
            }
            else dispatch(stop_loading())
        } catch (error) {
            console.log(error)
            dispatch(stop_loading())
        }
    }
}


export const action_delete_waybill = (id) => {
    return async (dispatch, getState) => {
        dispatch(component_reducer.start_component_loading());
        try {
            const response = await documentService.delete_waybill(id);
            if (checkStatus(response)) {
                dispatch(component_reducer.set_snack_bar_content({
                    message: `Successfully deleted waybill`,
                    type: 'success'
                }))

                dispatch(action_fetch_waybill_query_list());
            }
            else {
                dispatch(component_reducer.set_snack_bar_content({
                    message: `Something went wrong`,
                    type: 'error'
                }))
            }
            dispatch(component_reducer.stop_component_loading());
        } catch (error) {
            dispatch(component_reducer.set_snack_bar_content({
                message: error.message || 'Something went wrong',
                type: 'error'
            }))
            dispatch(component_reducer.stop_component_loading());
        }
    }
}